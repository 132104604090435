import React, { PureComponent } from "react";
import { graphql } from "gatsby";
import Layout from "../Layout/Layout";
import SEO from "../Layout/Seo";

class PagePrivacyTemplate extends PureComponent {
  render() {
    const currentPage = this.props.data.wordpressPage;
    const cleanDescription = currentPage.content.replace(/<\/?[^>]+(>|$)/g, "");
    const trimmedString = cleanDescription.substring(0, 300);
    return (
      <Layout>
        <SEO
          title={currentPage.yoast.title ? currentPage.yoast.title : currentPage.title}
          keywords={currentPage.yoast.metakeywords}
          description={currentPage.yoast.metadesc ? currentPage.yoast.metadesc : trimmedString}
        />
        <div className="page-template-default privacy-policy">
          <div className="section__header portfolio-list__header">
            <h2 className="section__title--large">{currentPage.title.replace("&#038;", "&")}</h2>
          </div>
          <div className="page">
            <div className="container">
              <div className="page__content">
                <div dangerouslySetInnerHTML={{ __html: currentPage.content }} />
              </div>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

export default PagePrivacyTemplate;

export const pageQuery = graphql`
  query privacyPageQuery($id: String!) {
    wordpressPage(id: { eq: $id }) {
      title
      content
      slug
      id
      date(formatString: "MMMM DD, YYYY")
      yoast {
        title
        metakeywords
        metadesc
      }
      acf {
        cover_photo {
          link
        }
      }
    }
  }
`;
