import React from "react";
import { FaFacebookF, FaInstagram, FaTwitter, FaLinkedinIn } from "react-icons/fa";

const SocialBar = () => {
  return (
    <div className="social">
      <ul className="social--list">
        <li className="social--item">
          <a
            href="https://www.facebook.com/PandaGroupdev/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="fa fa-facebook">
              <FaFacebookF />
            </i>
          </a>
        </li>
        <li className="social--item">
          <a href="https://instagram.com/pandagroupdev" target="_blank" rel="noopener noreferrer">
            <i className="fa fa-instagram">
              <FaInstagram />
            </i>
          </a>
        </li>
        <li className="social--item">
          <a href="https://twitter.com/PandaGroupdev" target="_blank" rel="noopener noreferrer">
            <i className="fa fa-twitter">
              <FaTwitter />
            </i>
          </a>
        </li>
        <li className="social--item">
          <a
            href="https://www.linkedin.com/company/pandagroupdev/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="fa fa-linkedin">
              <FaLinkedinIn />
            </i>
          </a>
        </li>
      </ul>
    </div>
  );
};

export default SocialBar;
