/* eslint-disable no-restricted-globals,no-undef,react/style-prop-object */
import React from "react";

const fmFCFka979n98sq = e => {
  if (e.data.type === "resize") {
    if (document.getElementById("fm-fc-f-ka979n98sq") !== null) {
      document.getElementById("fm-fc-f-ka979n98sq").style.minHeight = `${e.data.size}px`;
    }
  }
};

if (typeof document !== "undefined") {
  // eslint-disable-next-line no-unused-expressions
  document.addEventListener
    ? addEventListener("message", fmFCFka979n98sq, !1)
    : attachEvent("onmessage", fmFCFka979n98sq);
}

const CTA = () => {
  return (
    <section className="main-cta main-cta--newsletter">
      <div className="container">
        <div className="section__header">
          {/* <div className="section__title">Would you like to get info about new articles?</div> */}
          <div className="section__title">
            {/* eslint-disable-next-line global-require,import/no-unresolved */}
            <a href="https://pandagroup.co/core-web-vitals">
              <img
                /* eslint-disable-next-line global-require */
                src={require("../../images/ebook/ebook-banner.png")}
                alt="Core Web Vitals Ebook"
                className="ebook__image"
              />
            </a>
          </div>
        </div>
        <div className="section__form">
          {/* eslint-disable-next-line jsx-a11y/iframe-has-title */}
          <iframe
            id="fm-fc-f-ka979n98sq"
            src="https://forms.freshmail.io/f/w8wwvjlicv/ka979n98sq/index.html"
            frameBorder="0"
            marginHeight="0"
            marginWidth="0"
            width="100%"
          />
        </div>
      </div>
    </section>
  );
};

export default CTA;
