import React from "react";
import SocialBar from "../components/SocialBar/SocialBar";
import ContactTimer from "../modules/Contact/components/ContactTimer";
import OpeningHours from "../modules/Contact/components/ContactOpeningHours";
import CTANewsletter from "../modules/CTA/CTANewsletter";

const Footer = () => {
  return (
    <>
      <footer className="footer">
        <CTANewsletter />
        <SocialBar />
        <div className="container footer-content">
          <div className="footer__title">
            <h2>
              <span>Where is</span> <strong>Panda?</strong>
            </h2>
          </div>
          <div className="footer__columns">
            <div className="footer__column">
              <div className="contact__wrapper">
                <div className="footer-contact__wrapper">
                  <div className="footer-contact__item">
                    <span className="contact__item contact-area">
                      Poznań Science & Technology Park
                    </span>
                  </div>
                  <div className="footer-contact__item">
                    <span className="contact__item contact-address">
                      Rubież 46 C3/5 <br /> 61-612 Poznań, Poland
                    </span>
                  </div>
                </div>
                <div className="footer-contact__wrapper">
                  <div className="footer-contact__item">
                    <span className="contact__item contact-email">
                      <a href="mailto:sales@pandagroup.co" className="panda__email">
                        sales@pandagroup.co
                      </a>
                    </span>
                  </div>
                  <div className="footer-contact__item">
                    <span className="contact__item contact-phone">
                      <a href="tel:+48533738923">+48 533 738 923</a>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="footer__column">
              <div className="contact__wrapper">
                <div className="footer-contact__wrapper">
                  <div className="footer-contact__item">
                    <span className="contact__item contact-hours">Opening hours</span>
                  </div>
                  <div className="footer-contact__block">
                    <OpeningHours cssClass="footer-" />
                  </div>
                </div>
                <div className="footer-contact__time footer-contact__wrapper">
                  <ContactTimer />
                </div>
              </div>
            </div>
          </div>
          <div className="footer__legal">
            <div>Panda Group© 2022</div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
